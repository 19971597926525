<template>
<div>
  <!-- BREADCRUMB -->
  <breadcrumb-base :breadcrumb="breadcrumb" :title="data ? $t('UpdateDevice') : $t('AddDevice')">
    <template v-slot:right-end>
    </template>
  </breadcrumb-base>
  <div class="content-center">
    <div class="vx-row w-full mb-base c-center flex items-center justify-around">
      <div class="md:w-2/5 w-full flex">
        <vx-card :title="data ? $t('UpdateDevice') : $t('AddDevice')">
          <div class="vx-row">
            <div class="vx-col w-full mt-10 mb-6">
              <div class="mb-5">
                <vs-input disabled=""
                          v-if="data"
                          name="serial"
                          class="w-full" autofocus :placeholder="$t('SearchCustomer')"
                          :label="$t('SearchCustomer')" :value="customer.customer_id" />
                <dynamic-customers-drop-down
                  v-else
                  :placeholder="$t('SearchCustomer')"
                  :selected-item="customer || {}"
                  @on-item-selected="onCustomerChanged($event)">
                </dynamic-customers-drop-down>
              </div>
<!--              <vs-input  class="w-full mb-5" autofocus :placeholder="$t('DeviceTag')" v-model="deviceTag" />-->
              <div class="w-full mb-5">
                <vs-input v-validate="'required|min:3'"
                          validate-on="change"
                          name="serial"
                          class="w-full" autofocus :placeholder="$t('DeviceSerialNo') + '*'"
                          :label="$t('DeviceSerialNo') + '*'" v-model="deviceSerial" />

                <span class="text-danger text-sm">{{ errors.first('serial') }}</span>
              </div>
              <vs-checkbox class="vx-col mt-6" v-model="deviceStatus">{{ $t('Active') }}</vs-checkbox>
            </div>
          </div>
          <div class="vx-row ml-2">
            <vs-row>
              <vs-button :disabled="!validateForm" class="" @click="addDevice">{{ data ? $t('Update') : $t('Add') }}</vs-button>
              <div class="p-3"></div>
              <vs-button v-if="data" class="" color="danger" @click="deleteDeviceConfirmation">{{ $t('Delete') }}</vs-button>
            </vs-row>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'

export default {
  name: 'AddDevice',

  components: {
    DynamicCustomersDropDown,
  },
  data() {
    return {
      deviceTag: '',
      customer: null,
      deviceSerial: '',
      deviceStatus: true,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Add Device', i18n: 'AddDevice', active: true },
      ],
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.deviceSerial !== ''
    },
  },
  props: {
    data: {
      type: String,
      default: null,
    },
  },
  methods: {
    onCustomerChanged(c) {
      this.customer = c
    },
    addDevice() {
      if (this.customer == null) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select the customer',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const payload = {
        serial_no: this.deviceSerial,
        device_status: this.deviceStatus ? 1 : 0,
        customer_id: this.customer.customer_id,
        tag: this.deviceTag,
      }
      if (this.data) payload.update = 1
      this.$vs.loading()
      this.$store.dispatch('devices/addUpdateDevices', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.$router.push({ name: 'devices' })
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    deleteDeviceConfirmation() {
      this.$vs.dialog({
        color: 'danger',
        type: 'confirm',
        title: 'Confirmation',
        text: `Do you want to Delete Device ${this.deviceSerial} with tag ${this.deviceTag}`,
        accept: this.deleteDevice,
      })
    },
    deleteDevice() {
      const payload = {
        serial_no: this.deviceSerial,
        device_status: this.deviceStatus ? 1 : 0,
        tag: this.deviceTag,
      }
      payload.delete = 1
      this.$vs.loading()
      this.$store.dispatch('devices/addUpdateDevices', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.$router.push({ name: 'devices' })
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    if (this.data) {
      const j = JSON.parse(atob(this.data))
      this.deviceTag = j.tag
      this.deviceStatus = j.status === 1
      this.deviceSerial = j.serial_no
      if (j.customer_id) {
        this.customer = {
          'customer_id': j.customer_id,
        }
      }
    }
  },
}
</script>

<style scoped>
.dropdown {
  margin: 0 auto;
  max-width: 100%;
}
</style>
